import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageTransition from "vue-page-transition";
import axios from "axios";
import Snotify, { SnotifyPosition } from "vue-snotify";
import { mainAPI,mainAPI_IP } from "./API/mainAPI";
import VueApexCharts from "vue-apexcharts";
import VueDragscroll from "vue-dragscroll";
import "./plugins/vuetify-money.js";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

const optSnotify = {
  toast: {
    timeout: 1500,
    position: SnotifyPosition.rightTop,
  },
};

Vue.use(PerfectScrollbar);
Vue.use(VuePageTransition);
Vue.use(Snotify, optSnotify);
Vue.use(VueApexCharts);
Vue.use(VueDragscroll);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// GLOBAL COMPONENT
Vue.component("apexchart", VueApexCharts);
Vue.component("G-title", () => import("@/components/other/titleHeader.vue"));
Vue.component("G-contentImage", () =>
  import("@/components/card/contentImage.vue")
);
Vue.component("G-content", () => import("@/components/card/content.vue"));

Vue.component("G-dialogConfirm", () =>
  import("@/components/other/dialogConfirm.vue")
);
Vue.component("G-dialogModal", () =>
  import("@/components/other/dialogModal.vue")
);

Vue.component("G-input", () => import("@/components/form/input.vue"));
Vue.component("G-number", () => import("@/components/form/number.vue"));
Vue.component("G-money", () => import("@/components/form/money.vue"));
Vue.component("G-select", () => import("@/components/form/select.vue"));
Vue.component("G-select2", () => import("@/components/form/select2.vue"));
Vue.component("G-selectRef", () => import("@/components/form/selectRef.vue"));
Vue.component("G-textarea", () => import("@/components/form/textarea.vue"));
Vue.component("G-date", () => import("@/components/form/date.vue"));
Vue.component("G-password", () => import("@/components/form/password.vue"));
Vue.component("G-upload", () => import("@/components/form/UploadFile.vue"));
Vue.component("G-detail", () => import("@/components/form/detail.vue"));
Vue.component("G-no", () => import("@/components/form/no.vue"));

Vue.component("G-card", () => import("@/components/other/card.vue"));
Vue.component("G-buttonAct", () =>
  import("@/components/other/buttonLoader.vue")
);
Vue.component("G-buttonIconAct", () =>
  import("@/components/other/buttonLoaderIcon.vue")
);

Vue.component("G-notif", () => import("@/components/other/notif.vue"));

Vue.mixin({
  data: function() {
    return {
      get getUser() {
        return JSON.parse(localStorage.getItem("userLogin"));
      },
    };
  },
  methods: {
    G_GetAny(url) {
      return mainAPI
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function(res) {
          let resData = res;
          return resData;
        })
        .catch(function(error) {
          console.log(error.response);
          if (error.response.status == 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_DeleteAny(url) {
      return mainAPI
        .delete(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function(res) {
          let resData = res;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 403) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_PostAny(url, formData) {
      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      return mainAPI
        .post(url, object, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let resData = res.data;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_PutAny(url, formData) {
      var object = {};
      formData.forEach((value, key) => (object[key] = value));

      return mainAPI
        .put(url, object, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let resData = res.data;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_PostAnyCustom(url, formData) {
     
      return mainAPI
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let resData = res.data;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_PostAnyCustomIP(url, formData) {
     
      return mainAPI_IP.post(url, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let resData = res.data;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_PutAnyCustom(url, formData) {
   
      return mainAPI
        .put(url, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let resData = res.data;
          return resData;
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("userLogin");
            window.location.replace("/login");
          } else {
            return error.response;
          }
        });
    },
    G_GetRef(type, filter) {
      let setFilter = filter ? filter : "";
      return mainAPI
        .get("/reff/" + type + setFilter, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function(res) {

          let listData = [];
          let arrSub = {};
          
          let resData = [];

          if (type == 'donor') {
            resData = res.data;
          }else{
            resData = res.data.content;
          }

          resData.forEach((v,k) => {
            arrSub = {};

            switch (type) {
              case "balai":
                arrSub["text"] = v.nmbalai;
                arrSub["value"] = v.kdbalai;
                break;
                case "smFilter":
                  arrSub["text"] = v.smFilterNm;
                  arrSub["value"] = v.smFilterId;
                  break;
              case "donor":
                  arrSub["text"] = v;
                  arrSub["value"] = v;
                break;
              case "beban":
                arrSub["text"] = v.desc;
                arrSub["value"] = v.desc;
              break;
              case "proyek":
                arrSub["text"] = v.projekNm;
                arrSub["value"] = v.projekId;
                break;
              case "satker":
                arrSub["text"] = v.satkerNm;
                arrSub["value"] = v.satkerId;
                break;
              case "giat":
                arrSub["text"] = v.nmgiat;
                arrSub["value"] = v.kdgiat;
                break;

              case "register":
                arrSub["text"] = v.registerNm;
                arrSub["value"] = v.registerId;
                break;
              case "kategori":
                arrSub["text"] = v.nmkategori;
                arrSub["value"] = v.kategoriId;
                break;
              case "userType":
                arrSub["text"] = v.userTypeNm;
                arrSub["value"] = v.userTypeId;
                break;
              case "satker/type":
                arrSub["text"] = v.satkerTypeNm;
                arrSub["value"] = v.satkerTypeId;
                break;
              case "userGroup":
                arrSub["text"] = v;
                arrSub["value"] = v;
                break;
              case "akun":
                arrSub["text"] = v.kdakun + " - " + v.nmakun;
                arrSub["value"] = v.kdakun;
                break;
              case "beban":
                arrSub["text"] =
                  v.kdbeban + v.kdjnsban + v.kdctarik + " - " + v.desc;
                arrSub["value"] = v.kdbeban + v.kdjnsban + v.kdctarik;
                break;
            }

            listData.push(arrSub);
          });

          return listData;
        })
        .catch(function(error) {
          console.log(error);
          // if (error.response.status == 403) {
          //   localStorage.removeItem("token");
          //   localStorage.removeItem("userLogin");
          //   window.location.replace("/login");
          // } else {
          //   return error;
          // }
        });
    },
    G_Logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userLogin");

      this.$router.replace("/login");
    },
    G_limit(text, limit) {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    },
    G_today() {
      var date = new Date();
      var year = date.getFullYear();
      return year +"-" +("0" + (date.getMonth() + 1)).slice(-2) +"-" +("0" + date.getDate()).slice(-2);
    },
    
   
    G_formatDate(dates, type) {
      var date = new Date(dates);

      var monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      var monthNamesSmall = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Ags",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var sec = date.getSeconds();

      switch (type) {
        case "d":
          return day;
          break;
        case "m":
          return monthIndex + 1;
          break;
        case "mm":
          return monthNamesSmall[monthIndex + 1];
          break;
        case "M":
          return monthNames[monthIndex];
          break;
        case "Y":
          return year;
          break;
        case "d-m-Y":
          return day + "-" + (monthIndex + 1) + "-" + year;
          break;
        case "Y-m-d":
          return year + "-" + (monthIndex + 1) + "-" + day;
          break;
        case "d-M-Y":
          return day + " " + monthNames[monthIndex] + " " + year;
          break;
        case "d-m-Y H:i:s":
          return (
            day +
            "-" +
            (monthIndex + 1) +
            "-" +
            year +
            ", " +
            hour +
            ":" +
            minute +
            ":" +
            sec
          );
          break;
        case "d-M-Y H:i:s":
          return (
            day +
            " " +
            monthNames[monthIndex] +
            " " +
            year +
            ", " +
            hour +
            ":" +
            minute +
            ":" +
            sec
          );
          break;
      }
    },
    G_numFormatClear(data) {
      return data.replace(/\./g, "");
    },
    G_numFormat(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    G_darkTheme() {
      let theme;
      if ($("#setThemeDark").is(":checked")) {
        $("#kt_body").addClass("zn-bg-texture-dark");
        $("#kt_body").addClass("dark");
        theme = "dark";
        localStorage.setItem("theme", theme);
        this.$vuetify.theme.dark = true;
      } else {
        $("#kt_body").removeClass("zn-bg-texture-dark");
        $("#kt_body").removeClass("dark");
        theme = "white";
        localStorage.setItem("theme", theme);
        this.$vuetify.theme.dark = false;
      }
    },
    G_darkThemeInit() {
      if (localStorage.getItem("theme") == "dark") {
        $("#kt_body").addClass("zn-bg-texture-dark");
        $("#kt_body").addClass("dark");
        this.$vuetify.theme.dark = true;
        $("#setThemeDark").prop("checked", true);
        // this.$refs.rolesSelected.checked == true
      } else {
        $("#kt_body").removeClass("zn-bg-texture-dark");
        $("#kt_body").removeClass("dark");
        this.$vuetify.theme.dark = false;
        $("#setThemeDark").prop("checked", false);
        // this.$refs.rolesSelected.checked == false
      }
    },
    G_setSubmenu(type) {
      if (type == "hide") {
        $("#kt_aside").width(65);
        $("#contentView").css("paddingLeft", "65px");
      } else {
        $("#kt_aside").width(300);
        $("#contentView").css("paddingLeft", "300px");
      }
    },
    G_showProfileView() {
      $("#kt_quick_user").fadeIn();
      $("#kt_quick_user").css("right", 0);
      $(".offcanvas-overlay").fadeIn();
    },
    G_hideProfileView() {
      $("#kt_quick_user").css("right", "-395px");
      $("#kt_quick_user").fadeOut();
      $(".offcanvas-overlay").fadeOut();
    },
    G_tableCSV(name,idTable){
      var workbook = XLSX.utils.book_new();
        var worksheet_data  = document.getElementById(idTable);
        var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
        workbook.SheetNames.push(name);
        workbook.Sheets[name] = worksheet;
      
         return XLSX.writeFile(workbook, name+' '+this.G_formatDate(Date(),'d-M-Y')+'.xlsx');
    },
    G_tableExcel(name,idTable){

      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel';
      var table_div = document.getElementById(idTable);
      var table_html = table_div.outerHTML.replace(/ /g, '%20');
      a.href = data_type + ', ' + table_html;
      a.download = name+' '+this.G_formatDate(Date(),'d-M-Y')+'.xls';
      a.click();
      e.preventDefault();
    },
  },

  computed: {
    G_dateNow() {
      var date = new Date();
      var year = date.getFullYear();
      return ("0" + date.getDate()).slice(-2)+'-'+("0" + (date.getMonth() + 1)).slice(-2) ;
    },
    G_yearNow() {
      var date = new Date();
      var year = date.getFullYear();
      return year;
    },
    G_titleRKAKL() {
      switch (this.$route.query.eventId) {
        case "0":
          return "Tools ADK"  
        break;
        case "1":
          return "Usulan RKAKL Tahun Berjalan +1 (2023) ";
          break;
        case "2":
          return " RKAKL Tahun Berjalan (2022) ";
          break;
        case "3":
          return "Usulan Revisi RKAKL 2022 ITJEN";
          break;
        case "4":
          return "Usulan Revisi RKAKL 2022 DJA";
          break;
        default:
          return "Usulan RKAKL Tahun Berjalan +1 (2023) ";
      }
    },
    titleSandingan(){
      switch (this.$route.query.eventId) {
        case "4":
          return "Sandingan RKAKL DJA vs ITJEN"  
        break;
        case "5":
          return "Sandingan RKAKL DJA dengan RKAKL Sekarang"  
        break;
        case "3":
          return "Sandingan RKAKL Revisi vs RKAKL 2022"  
        break;
      
        default:
          return "Semula Menjadi"  

          break;
      }
    },
    titleSemula(){
      switch (this.$route.query.sEventId) {
        case "4":
          return "Usulan Revisi RKAKL 2022 DJA"  
        break;
        case "3":
          return "Usulan Revisi RKAKL 2022 ITJEN"  
        break;
        case "2":
          return "RKAKL 2022"  
        break;
        case "1":
          return "Usulan RKAKL 2023"  
        break;
        case "0":
          return "Tools ADK"  
        break;
      
        default:
          return "-"  

          break;
      }
    },
    titleMenjadi(){
      switch (this.$route.query.mEventId) {
        case "4":
          return "Usulan Revisi RKAKL 2022 DJA"  
        break;
        case "3":
          return "Usulan Revisi RKAKL 2022 ITJEN"  
        break;
        case "2":
          return "RKAKL 2022"  
        break;
        case "1":
          return "Usulan RKAKL 2023"  
        break;
        case "0":
          return "Tools ADK"  
        break;
      
        default:
          return "-"  

          break;
      }
    },
  },
});
