import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [
  {
    path: "/public/progres_awp",
    name: "public_progres_awp",
    component: () => import("@/views/public_progres_awp/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/dashboard/balai",
    name: "HomeBalai",
    component: () => import("@/views/home_balai/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/dashboard/donor",
    name: "HomeDonor",
    component: () => import("@/views/home_donor/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/dashboard/loan",
    name: "HomeLoan",
    component: () => import("@/views/home_loan/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: "/adk/kirim_approval/detail/:id",
    name: "adk_kirim_approval_detail",
    component: () => import("@/views/adk/kirim_approval_detail/index.vue"),
    meta: {
      transition: "zoom",
    },
    props: true,
  },
];

const progress = ["per_paket", "prognosis"];
progress.forEach((t) => {
  routes.push({
    path: "/progress/" + t,
    name: "progress_" + t,
    component: () => import("@/views/progress/" + t + "/index.vue"),
    meta: {
      transition: "zoom",
    },
  });
});

const kontraktual = ["pelaksanaan"];
kontraktual.forEach((t) => {
  routes.push({
    path: "/kontraktual/" + t,
    name: "kontraktual_" + t,
    component: () => import("@/views/kontraktual/" + t + "/index.vue"),
    meta: {
      transition: "zoom",
    },
  });
});

const adk = [
  "kelompok_revisi",
  "semula_menjadi",
  "semula_menjadi_gabungan",
  "resume",
  "resume_porsi",
  "resume_beban",
  "resume_kppn",
  "resume_akun",
  "event",
  "manage",
  "upload",
  "cek_pagu",
  "versi",
  "kirim_approval",
  "approval",
  "emon",
  "omspan",
  "pagu_pusat",
  "pagu_minus",
  "pagu_direktorat",
  "pagu_pusat_upload",
  "pagu_direktorat_upload",
  "sandingan_rkakl",
  "multi_proyek",
];
adk.forEach((t) => {
  routes.push({
    path: "/adk/" + t,
    name: "adk_" + t,
    component: () => import("@/views/adk/" + t + "/index.vue"),
    meta: {
      transition: "zoom",
    },
  });
});

const md = [
  "user",
  "porsi_loan",
  "kkpn",
  "konfigurasi_register",
  "kegiatan_direktorat",
  "register",
  "dokumen"
];
md.forEach((t) => {
  routes.push({
    path: "/md/" + t,
    name: "md_" + t,
    component: () => import("@/views/md/" + t + "/index.vue"),
    meta: {
      transition: "zoom",
    },
  });
});

const emon = ["sandingan","belum_lelang","punya_sub","proyek","progres","phln","realisasi_pmis","need_payment","need_field","monitoring_awp"];
emon.forEach((t) => {
  routes.push({
    path: "/emon/" + t,
    name: "emon_" + t,
    component: () => import("@/views/emon/" + t + "/index.vue"),
    meta: {
      transition: "zoom",
    },
  });
});

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem("token") || false;

  if (to.name === "public_progres_awp"){
    next();
  }else{
    if (to.name !== "Login" && !isAuthenticated)
    next({
      name: "Login",
    });
  if (to.name === "Login" && isAuthenticated)
    next({
      name: "Home",
    });
  else next();
  }
    

 
});

export default router;
