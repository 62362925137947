import axios from "axios";

window.token = localStorage.getItem("token");

export const mainAPI = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export const mainAPI_IP = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});
