import {GetRef,GetAny,PostAny} from '@/globalFunction'

const actions = {
    getHomeCategory(state) {
        return new Promise((resolve, reject) => {
            GetAny('/client/get/kategori-donasi').then(data => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    },
};

const state = {
    homeCategory: [],
};

const getters = {
    homeCategory: (state) => state.homeCategory
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
}