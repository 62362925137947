<template>
<v-app>
      <!-- <div id="bsloader-container" class="bsloader-container">
        <div class="bsprogress bsfloat bsshadow">
          <div class="bsprogress__item"></div>
        </div>
      </div> -->

    <div id="container-loading">
        <div class='face' id="loading_custom">
            <div class='container'>
            <img src='@/assets/logo_pu.jpg' style="border-radius: 25px !important;" height='90px'/>
            <span class='loading'></span>
            <div class="caption">
                <ul>
                <li>L</li>
                <li>O</li>
                <li>A</li>
                <li>D</li>
                <li>I</li>
                <li>N</li>
                <li>G</li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </div>

    <component :is="layout">
      <vue-page-transition name="fade-in-up">
         <router-view :key="$route.fullPath" :layout.sync="layout"/>
      </vue-page-transition>
    </component>
</v-app>
</template>

<script>

export default {
  name: `App`,
  data() {
    return {
      layout: `div`,
    };
  },
  mounted() {
    $('#container-loading').delay(1000).fadeOut('slow');	
  }
};
</script>