import {mainAPI} from "./API/mainAPI";

export function GetAny(url) {
    return mainAPI.get(url)
        .then(function (res) {
            let resData = res.data.data;
            return resData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");
            } else {
                return error.response;
            }
        })
}

export function PostAny(url, formData) {
    return mainAPI.post(url, formData)
        .then(res => {
            let resData = res.data;
            return resData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");

            } else {
                return error.response;
            }
        })
}

export function GetRef(type, filter) {
    let setFilter = (filter) ? filter : '';
    return mainAPI.get('/ref/get/' + type + setFilter)
        .then(function (res) {
            let listData = [];
            let arrSub = {};
            let resData = res.data.data;

            resData.forEach(v => {
                arrSub = {};

                switch (type) {
                    case 'provinsi':
                        arrSub['text'] = v.provinceName;
                        arrSub['value'] = v.provinceId;
                        break;
                    case 'kota':
                        arrSub['text'] = v.cityName;
                        arrSub['value'] = v.cityId;
                        break;
                    case 'agama':
                        arrSub['text'] = v.definition;
                        arrSub['value'] = v.id;
                        break;
                    case 'yayasan':
                        arrSub['text'] = v.nama;
                        arrSub['value'] = v.id;
                        break;
                    case 'pendidikan':
                        arrSub['text'] = v.pendidikanName;
                        arrSub['value'] = v.id;
                        break;
                    case 'kategori':
                        arrSub['text'] = v.namaKategori;
                        arrSub['value'] = v.id;
                        break;
                    case 'jenis_kelamin':
                        arrSub['text'] = v.name;
                        arrSub['value'] = v.id;
                        break;
                    case 'student':
                        arrSub['text'] = v.firstName + '-' + v.namaYayasan;
                        arrSub['value'] = v.id;
                        break;
                    case 'role':
                        arrSub['text'] = v.roleName;
                        arrSub['value'] = v.roleId;
                        break;
                }

                listData.push(arrSub);
            });

            return listData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");

            } else {
                return error.response;
            }
        })
}


//SP2D
export function GetAnySP2D(url) {
    return sp2dAPI.get(url)
        .then(function (res) {
            let resData = res;
            return resData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");
            } else {
                return error.response;
            }
        })
}

export function PostAnySP2D(url, formData) {
    var object = {};
    formData.forEach((value, key) => object[key] = value);
    var json = JSON.stringify(object);

    return sp2dAPI.post(url, json)
        .then(res => {
            let resData = res;
            return resData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");

            } else {
                return error.response;
            }
        })
}

// RAJAONGKIR

export function GetAnyRajaOngkir(url) {
    return rajaOngkirAPI.get(url)
        .then(function (res) {
            let resData = res;
            return resData;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('userLogin');
                window.location.replace("/login");
            } else {
                return error.response;
            }
        })
}